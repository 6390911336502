.date-picker-input {
  width: 260px;
  border-bottom: 2px solid rgba(89, 56, 34, 0.32); // GreySemiLight

  &:active {
    border-color: #e98310; // Orange
  }

  .react-date-picker__wrapper {
    border: none !important;
  }

  .react-date-picker__inputGroup {
    font-size: 24px;
    line-height: 36px;
  }

  .react-date-picker__button:enabled:hover .react-date-picker__button__icon {
    stroke: #e98310 !important; // Orange
  }
}
